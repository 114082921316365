import LanguageModal from "../LanguageModal";
import Logo from "../Logo";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WorkspaceSelector from "./Workspace";
import ProfileModal from "../Profile";

interface Navlinks {
  name: string;
  href: string;
}

export default function Navbar(): React.JSX.Element {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isStats = (path: string) => pathname.includes(path);

  const navLinks: Navlinks[] = [
    {
      href: "/dashboard",
      name: t("tenants"),
    },
    // {
    //   href: "/dashboard/stats",
    //   name: t("stats"),
    // },
  ];

  return (
    <header className="flex items-center justify-between bg-white px-8 py-2.5">
      <section className="flex flex-col items-center">
        <Logo className="ltr:size-[58px] rtl:size-[77px]" />
        <WorkspaceSelector />
      </section>
      <section className="flex gap-4">
        {navLinks.map(nav => (
          <Link
            key={nav.href}
            to={nav.href}
            className={`${isStats(nav.href) ? "font-semibold text-primaryGreen" : "font-medium"} text-lg duration-150 hover:text-primaryGreen`}
          >
            {nav.name}
          </Link>
        ))}
      </section>
      <section className="flex items-center gap-7">
        <LanguageModal />
        <ProfileModal />
      </section>
    </header>
  );
}
