import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

interface CaisseContextType {
  maxAllowedNumberOfMonthsToPay: number;
  minAllowedNumberOfMonthsToPay: number;
  tenantId: string;
  tenantName: string;
  lastPaidDate: string;
  transactionRefrence?: string;
  setTransactionRefrence: Dispatch<SetStateAction<string>>;
}

interface CaisseProviderProps {
  children: React.ReactNode;
  maxAllowedNumberOfMonthsToPay: number;
  minAllowedNumberOfMonthsToPay: number;
  tenantId: string;
  tenantName: string;
  lastPaidDate: string;
}

export const CaisseContext = createContext<CaisseContextType | null>(null);

export function CaisseProvider({
  children,
  maxAllowedNumberOfMonthsToPay,
  minAllowedNumberOfMonthsToPay,
  tenantId,
  tenantName,
  lastPaidDate,
}: CaisseProviderProps): React.JSX.Element {
  const [transactionRefrence, setTransactionRefrence] = useState<string>("");

  return (
    <CaisseContext.Provider
      value={{
        maxAllowedNumberOfMonthsToPay,
        minAllowedNumberOfMonthsToPay,
        tenantId,
        tenantName,
        setTransactionRefrence,
        transactionRefrence,
        lastPaidDate,
      }}
    >
      {children}
    </CaisseContext.Provider>
  );
}

export function useCaisse(): CaisseContextType {
  const context = useContext(CaisseContext);

  if (!context) {
    throw new Error("useCaisse must be used within a CaisseProvider");
  }

  return context;
}
