import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { axiosInstance } from "./axios";
import { LoginResponse } from "../types/response/LoginResponse";
import { LoginRequest, ResetPasswordRequest, SignupRequest } from "../types/request/LoginRequest";
import { ErrorResponse } from "../types/response";
import { ENDPOINTS } from "./endpoints";
import toast from "react-hot-toast";
import SubmitResponseHandler from "../components/SubmitResponseHandler";

export function useLogin(
  options?: Omit<UseMutationOptions<LoginResponse, ErrorResponse, LoginRequest>, "mutationFn"> | undefined,
) {
  const queryClient = useQueryClient();

  async function login(value: LoginRequest) {
    try {
      const response = await axiosInstance.post(ENDPOINTS.AUTH_LOGIN_ENDPOINT, { ...value, device: "web" });

      return response.data.response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<LoginResponse, ErrorResponse, LoginRequest>(login, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
    onError: error => {
      console.log(error);
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },
    ...options,
  });
}

export function useSignup(
  options?: Omit<UseMutationOptions<void, ErrorResponse, SignupRequest>, "mutationFn"> | undefined,
) {
  async function signup(value: SignupRequest) {
    const response = await axiosInstance.post("/signup", value);

    if (response.status !== 200) {
      throw response.data;
    }

    return response.data;
  }

  return useMutation<void, ErrorResponse, SignupRequest>(signup, {
    ...options,
  });
}

export function useResetPassword(
  options?: Omit<UseMutationOptions<void, ErrorResponse, ResetPasswordRequest>, "mutationFn"> | undefined,
) {
  async function resetPassword(value: ResetPasswordRequest) {
    const response = await axiosInstance.post("/reset-password", value);

    if (response.status !== 200) {
      throw response.data;
    }

    return response.data;
  }

  return useMutation<void, ErrorResponse, ResetPasswordRequest>(resetPassword, {
    ...options,
  });
}

export function useLogout(
  options?: Omit<UseMutationOptions<unknown, ErrorResponse, string>, "mutationFn"> | undefined,
) {
  const queryClient = useQueryClient();

  async function logout(token: string) {
    try {
      const response = await axiosInstance.post(ENDPOINTS.AUTH_LOGOUT_ENDPOINT + token);

      return response.data;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, string>(logout, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
    onError: error => {
      console.log(error);
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },
    ...options,
  });
}
