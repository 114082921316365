export const formatCreatedAtDate = (t: (key: string) => string, createdAt?: string): string => {
  console.log(createdAt);

  if (!createdAt) return "-";

  const dateObj = new Date(createdAt);
  const monthIndex = dateObj.getMonth() + 1;
  const monthName = t(monthIndex.toString());

  const [datePart] = createdAt.split("T");
  const [year] = datePart.split("-");

  return `${monthName} ${year}`;
};
