import { useTranslation } from "react-i18next";
import { CaisseVirementResponseBody } from "../../../types/request/CaisseRequest";
import { useFormik } from "formik";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import InputField from "../../../components/Inputs/InputField";
import InputCheckbox from "../../../components/Inputs/InputCheckbox";
import { createVirementValidator } from "./validator";
import { useCreateVirmentTransaction, usePostQuote } from "../../../api/payments";
import { CAISSETYPE } from "../../../constants";
import Spinner from "../../../components/ui/Spinner";
import { useCaisse } from "./Provider";
import { useEffect } from "react";
import { TextMoneyFormat } from "../../../utils/TextMoneyFormat";
import toast from "react-hot-toast";
import { formatCreatedAtDate } from "../../../utils/formatDates";

export default function Virement(): React.JSX.Element {
  const {
    maxAllowedNumberOfMonthsToPay,
    minAllowedNumberOfMonthsToPay,
    tenantId,
    setTransactionRefrence,
    transactionRefrence,
    lastPaidDate,
  } = useCaisse();
  const { isLoading: isLoadingPayment, mutate: createVirementTransaction } = useCreateVirmentTransaction({
    onSuccess() {
      toast.success(t("paymentSuccess"));
    },
  });
  const {
    isLoading: isLoadingQuote,
    data,
    mutate: getQuote,
  } = usePostQuote(tenantId, {
    onSuccess(data) {
      setTransactionRefrence(data.transactionReference);
    },
  });

  const isLoading: boolean = isLoadingPayment || isLoadingQuote;

  const { t } = useTranslation();

  const initialValues: CaisseVirementResponseBody = {
    bank: "",
    virementNumber: "",
    months: NaN,
    date: "",
    ignoreCaution: false,
    ignoreRegistrationFee: false,
    ignoreManagementFee: false,
  };

  const formik = useFormik<CaisseVirementResponseBody>({
    initialValues,
    validationSchema: createVirementValidator(t, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay),
    onSubmit: values => {
      if (!transactionRefrence) return;

      createVirementTransaction({
        ...values,
        type: CAISSETYPE[1],
        tenantId: tenantId,
        numberOfMonths: values.months,
        transactionReference: transactionRefrence,
      });
    },
  });

  useEffect(() => {
    if (
      formik.values.months >= minAllowedNumberOfMonthsToPay &&
      formik.values.months <= maxAllowedNumberOfMonthsToPay
    ) {
      getQuote({
        type: CAISSETYPE[0],
        numberOfMonths: formik.values.months,
        ignoreDeposit: formik.values.ignoreCaution,
        ignoreManagmentFees: formik.values.ignoreManagementFee,
        ignoreRegisterFees: formik.values.ignoreRegistrationFee,
      });
    }
  }, [
    formik.values.months,
    minAllowedNumberOfMonthsToPay,
    maxAllowedNumberOfMonthsToPay,
    getQuote,
    formik.values.ignoreCaution,
    formik.values.ignoreManagementFee,
    formik.values.ignoreRegistrationFee,
  ]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="grid grid-cols-2 gap-8 px-10 pt-2 lg:px-16"
    >
      <InputField
        label="bank"
        name="bank"
        onChange={formik.handleChange}
        placeholder=""
        type="text"
        value={formik.values.bank}
        error={formik.errors.bank}
      />

      <InputField
        label="virementNumber"
        name="virementNumber"
        onChange={formik.handleChange}
        placeholder=""
        type="text"
        value={formik.values.virementNumber}
        error={formik.errors.virementNumber}
      />

      <InputField
        label="monthsToPay"
        name="months"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.months}
        error={formik.errors.months}
      />

      <InputField
        label="paymentDate"
        name="date"
        onChange={formik.handleChange}
        placeholder=""
        type="date"
        value={formik.values.date}
        error={formik.errors.date}
      />

      <div className="col-span-2 flex flex-wrap items-center justify-around gap-6">
        <section className="flex flex-wrap items-center gap-2">
          <span className="font-medium text-neutral-800">{t("period")} :</span>
          <span className="font-semibold text-primaryGreen">
            {formatCreatedAtDate(t, lastPaidDate)}
            {lastPaidDate &&
              formik.values.months > 1 &&
              " - " +
                formatCreatedAtDate(
                  t,
                  new Date(
                    new Date(lastPaidDate).setMonth(new Date(lastPaidDate).getMonth() + formik.values.months - 1),
                  )
                    .toISOString()
                    .replace(".000Z", ""),
                )}
          </span>
        </section>
        <section className="flex items-center justify-between gap-6">
          <InputCheckbox
            label="ignoreCaution"
            name="ignoreCaution"
            value={formik.values.ignoreCaution}
            onChange={formik.handleChange}
          />

          <InputCheckbox
            label="ignoreRegistrationFee"
            name="ignoreRegistrationFee"
            value={formik.values.ignoreRegistrationFee}
            onChange={formik.handleChange}
          />

          <InputCheckbox
            label="ignoreManagementFee"
            name="ignoreManagementFee"
            value={formik.values.ignoreManagementFee}
            onChange={formik.handleChange}
          />
        </section>
      </div>

      <section className="col-span-2 flex flex-col items-center justify-center space-y-4">
        <div className="flex gap-4">
          <span className="text-2xl font-semibold">{t("netAPay")}</span>
          <span className="text-2xl font-semibold text-red-500">
            {isLoadingQuote ? <Spinner /> : TextMoneyFormat(data?.totalQuote || 0, t)}
          </span>
        </div>
        <PrimaryButton disabled={isLoading}>{isLoading ? <Spinner /> : t("saveRecu")}</PrimaryButton>
      </section>
    </form>
  );
}
