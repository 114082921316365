import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Spinner from "../../components/ui/Spinner";
import { LoginRequest } from "../../types/request/LoginRequest";
import { useFormik } from "formik";
import AuthInput from "./Inputs";
import { ReactComponent as EmailIcon } from "../../assets/Auth/email.svg";
import createLoginSchema from "./validator";
import { useAuth } from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";

export default function Login(): React.JSX.Element {
  const { t } = useTranslation();
  const { isLoading, mutate: login } = useAuth().login;
  const navigate = useNavigate();

  const initialValues: LoginRequest = {
    password: "",
    pseudo: "",
  };

  const formik = useFormik<LoginRequest>({
    initialValues,
    validationSchema: createLoginSchema(t),
    onSubmit: values => {
      login(values, {
        onSuccess() {
          navigate("/dashboard");
        },
      });
    },
  });

  return (
    <main className="z-50 mx-auto w-[clamp(300px,100%,545px)] rounded-lg border bg-white px-4 py-10 shadow-lg sm:px-6">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-6"
      >
        <section className="text-center">
          <h2 className="text-xl font-bold md:text-2xl">{t("loginTitle")}</h2>
          <p className="text-sm font-normal text-primaryGray md:text-base">{t("loginDesc")}</p>
        </section>

        <AuthInput
          label="username"
          name="pseudo"
          onChange={formik.handleChange}
          placeholder="username"
          type="text"
          value={formik.values.pseudo}
          error={formik.errors.pseudo}
          Icon={EmailIcon}
        />

        <AuthInput
          label="password"
          name="password"
          onChange={formik.handleChange}
          placeholder="password"
          type="password"
          value={formik.values.password}
          error={formik.errors.password}
        />

        <PrimaryButton
          disabled={isLoading}
          className="flex items-center justify-center"
        >
          {isLoading ? <Spinner /> : t("login")}
        </PrimaryButton>
      </form>
    </main>
  );
}
