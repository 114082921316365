import { useTranslation } from "react-i18next";
import { TenantInfoResponseBody } from "../../types/response/TenantsResponseBody";
import { formatCreatedAtDate } from "../../utils/formatDates";
import { TextMoneyFormat } from "../../utils/TextMoneyFormat";

interface Props {
  tenantInfo: TenantInfoResponseBody;
}

export default function TenantDetail({ tenantInfo }: Props) {
  const { t } = useTranslation();

  const renderField = (label: string, value: string | number) => (
    <div className="flex w-full items-center gap-x-4">
      <p className="min-w-[150px] text-sm font-bold text-primaryBlack lg:text-sm">{label}</p>
      <p className="text-sm text-primaryGray lg:text-base">{value ? value : "0.00 DZD"}</p>
    </div>
  );

  const tvaFactor = (tenantInfo?.tvaFactor ? tenantInfo?.tvaFactor : 0.09) * 100;
  const tva = tenantInfo?.tva ? tenantInfo?.tva : 0.0;
  const penaltyAmount = tenantInfo?.penalty ? tenantInfo?.penalty : 0.0;
  const taxe = tenantInfo?.tax ? tenantInfo?.tax : 0.0;
  const legalFees = tenantInfo?.legalFees ? tenantInfo?.legalFees : 0.0;
  const otherFees = tenantInfo?.otherFees ? tenantInfo?.otherFees : 0.0;

  return (
    <article>
      <div className="w-full">
        <div className="grid gap-x-14 gap-y-6 xl:grid-cols-2">
          {tenantInfo && (
            <>
              <div className="w-full flex-col rounded-md bg-white p-4 shadow">
                <h1 className={`py-2 text-base font-bold text-primaryBlack lg:text-xl`}>{t("tenantInfos")}</h1>
                {renderField(t("fullName"), tenantInfo.fullName)}
                {renderField(t("tenantCode"), tenantInfo.tenantPublicCode)}
                {tenantInfo && renderField(t("address"), tenantInfo.address)}
              </div>

              <div className="w-full flex-col rounded-md bg-white p-4 shadow">
                {tenantInfo && (
                  <>
                    <h1 className={`py-2 text-base font-bold text-primaryBlack lg:text-xl`}>{t("paymentInfos")}</h1>
                    <div className="grid grid-cols-2 gap-x-6">
                      {renderField(t("lastPaidDate"), formatCreatedAtDate(t, tenantInfo.lastPaidDate))}
                      {renderField(
                        t("unpaidMonth"),
                        `${
                          tenantInfo?.numberOfMonthsToPay
                            ? tenantInfo?.numberOfMonthsToPay + " " + t("months")
                            : "0 " + t("months")
                        }`,
                      )}
                      {renderField(t("loyerPerMonth"), TextMoneyFormat(tenantInfo?.rent, t))}
                      {renderField(t("charge"), TextMoneyFormat(tenantInfo?.charge, t))}
                      {renderField(t("tva") + "(" + tvaFactor + "%) :", TextMoneyFormat(tva, t))}
                      {renderField(t("penalty") + "(5%) :", TextMoneyFormat(penaltyAmount, t))}
                      {renderField(t("taxe"), TextMoneyFormat(taxe, t))}
                      {renderField(t("otherFees"), TextMoneyFormat(otherFees, t))}
                      {renderField(t("justiceFees"), TextMoneyFormat(legalFees, t))}
                      {renderField(t("HT"), TextMoneyFormat(Number(tenantInfo?.ht), t))}
                      {renderField(t("TTC"), TextMoneyFormat(Number(tenantInfo?.ttc), t))}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </article>
  );
}
