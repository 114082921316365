import { useFormik } from "formik";
import InputField from "../../../components/Inputs/InputField";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { createEspeceValidator } from "./validator";
import { CaisseEspeceResponseBody } from "../../../types/request/CaisseRequest";
import { useCreateCashTransaction, usePostQuote } from "../../../api/payments";
import { CAISSETYPE } from "../../../constants";
import Spinner from "../../../components/ui/Spinner";
import { useCaisse } from "./Provider";
import { useEffect, useRef } from "react";
import { TextMoneyFormat } from "../../../utils/TextMoneyFormat";
import toast from "react-hot-toast";
import { formatCreatedAtDate } from "../../../utils/formatDates";

export default function Espece(): React.JSX.Element {
  const {
    maxAllowedNumberOfMonthsToPay,
    minAllowedNumberOfMonthsToPay,
    tenantId,
    setTransactionRefrence,
    transactionRefrence,
    lastPaidDate,
  } = useCaisse();

  const { isLoading: isLoadingPayment, mutate: createEspeceTransaction } = useCreateCashTransaction({
    onSuccess() {
      toast.success(t("paymentSuccess"));
    },
  });

  const {
    isLoading: isLoadingQuote,
    data,
    mutate: getQuote,
  } = usePostQuote(tenantId, {
    onSuccess(data) {
      setTransactionRefrence(data.transactionReference);
    },
  });

  const isLoading: boolean = isLoadingPayment || isLoadingQuote;

  const { t } = useTranslation();
  const handedOverRef = useRef<number>();

  const initialValues: CaisseEspeceResponseBody = {
    handedOver: NaN,
    price: NaN,
    months: NaN,
  };

  const formik = useFormik<CaisseEspeceResponseBody>({
    initialValues,
    validationSchema: createEspeceValidator(t, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay),
    onSubmit: values => {
      if (!transactionRefrence) return;

      createEspeceTransaction({
        ...values,
        type: CAISSETYPE[2],
        tenantId: tenantId,
        numberOfMonths: values.months,
        transactionReference: transactionRefrence,
      });
    },
  });

  useEffect(() => {
    if (
      formik.values.months >= minAllowedNumberOfMonthsToPay &&
      formik.values.months <= maxAllowedNumberOfMonthsToPay
    ) {
      getQuote({
        type: CAISSETYPE[0],
        numberOfMonths: formik.values.months,
        ignoreDeposit: false,
        ignoreManagmentFees: false,
        ignoreRegisterFees: false,
      });
    }
  }, [formik.values.months, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay, getQuote]);

  useEffect(() => {
    if (data?.totalQuote && formik.values.price) {
      const handedOver = Math.round(formik.values.price - data.totalQuote);

      if (handedOver === handedOverRef.current) return;

      handedOverRef.current = handedOver;
      formik.setFieldValue("handedOver", handedOver);
    }
  }, [data?.totalQuote, formik.values.price, formik]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="grid grid-cols-2 gap-8 px-10 pt-2 lg:px-16"
    >
      <InputField
        label="monthsToPay"
        name="months"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.months}
        error={formik.errors.months}
      />

      <InputField
        label="priceRecieved"
        name="price"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.price}
        error={formik.errors.price}
      />

      <section className="flex items-center gap-2">
        <span className="font-medium text-neutral-800">{t("period")} :</span>
        <span className="font-semibold text-primaryGreen">
          {formatCreatedAtDate(t, lastPaidDate)}
          {lastPaidDate &&
            formik.values.months > 1 &&
            " - " +
              formatCreatedAtDate(
                t,
                new Date(new Date(lastPaidDate).setMonth(new Date(lastPaidDate).getMonth() + formik.values.months - 1))
                  .toISOString()
                  .replace(".000Z", ""),
              )}
        </span>
      </section>

      <InputField
        label="handedOver"
        name="handedOver"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.handedOver}
        error={formik.errors.handedOver}
        readonly
      />

      <section className="col-span-2 flex flex-col items-center justify-center space-y-4">
        <div className="flex gap-4">
          <span className="text-2xl font-semibold">{t("netAPay")}</span>
          <span className="text-2xl font-semibold text-red-500">
            {isLoadingQuote ? <Spinner /> : TextMoneyFormat(data?.totalQuote || 0, t)}
          </span>
        </div>
        <PrimaryButton disabled={isLoading}>{isLoading ? <Spinner /> : t("saveRecu")}</PrimaryButton>
      </section>
    </form>
  );
}
