import { useMutation, UseMutationOptions } from "react-query";
import { axiosInstance } from "./axios";
import { ENDPOINTS } from "./endpoints";
import {
  CaisseChequeResponseBody,
  CaisseEspeceResponseBody,
  CaisseRequestBody,
  CaisseVirementResponseBody,
  QuoteRequestBody,
} from "../types/request/CaisseRequest";
import toast from "react-hot-toast";
import SubmitResponseHandler from "../components/SubmitResponseHandler";
import { ErrorResponse } from "../types/response";
import { QuoteResponseBody } from "../types/response/CaisseResponse";

export function useDownloadInvoice(options?: Omit<UseMutationOptions<any, ErrorResponse, string>, "mutationFn">) {
  async function downloadInvoice(transactionReference: string) {
    try {
      const response = await axiosInstance.get(ENDPOINTS.DOWNLOAD_INVOICE + transactionReference, {
        responseType: "blob",
      });

      return response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<any, ErrorResponse, string>(downloadInvoice, {
    ...options,
  });
}

export function usePostQuote(
  tenantId: string,
  options?: Omit<UseMutationOptions<QuoteResponseBody, ErrorResponse, QuoteRequestBody>, "mutationFn"> | undefined,
) {
  async function postQuote(values: QuoteRequestBody) {
    try {
      const response = await axiosInstance.post(ENDPOINTS.CAISSE_QUOTE_TRANSACTION_ENDPOINT + tenantId, values);

      return response.data.response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<QuoteResponseBody, ErrorResponse, QuoteRequestBody>(postQuote, {
    onError: error => {
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },

    ...options,
  });
}

export function useCreateChequeTransaction(
  options?:
    | Omit<UseMutationOptions<unknown, ErrorResponse, CaisseChequeResponseBody & CaisseRequestBody>, "mutationFn">
    | undefined,
) {
  async function createChequeTransaction(values: CaisseChequeResponseBody & CaisseRequestBody) {
    try {
      const response = await axiosInstance.post(ENDPOINTS.CAISSE_CREATE_TRANSACTION_ENDPOINT, {
        type: values.type,
        tenantId: values.tenantId,
        numberOfMonths: values.numberOfMonths,
        transactionReference: values.transactionReference,
        content: JSON.stringify({
          author: values.author,
          bank: values.bank,
          chequeNumber: values.chequeNumber,
        }),
      });

      return response.data.response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, CaisseChequeResponseBody & CaisseRequestBody>(createChequeTransaction, {
    onError: error => {
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },

    ...options,
  });
}

export function useCreateVirmentTransaction(
  options?:
    | Omit<UseMutationOptions<unknown, ErrorResponse, CaisseVirementResponseBody & CaisseRequestBody>, "mutationFn">
    | undefined,
) {
  async function createVirementTransaction(values: CaisseVirementResponseBody & CaisseRequestBody) {
    try {
      const response = await axiosInstance.post(ENDPOINTS.CAISSE_CREATE_TRANSACTION_ENDPOINT, {
        type: values.type,
        tenantId: values.tenantId,
        numberOfMonths: values.months,
        transactionReference: values.transactionReference,
        content: JSON.stringify({
          bank: values.bank,
          virementNumber: values.virementNumber,
          date: values.date,
          ignoreDeposit: values.ignoreCaution,
          ignoreRegisterFees: values.ignoreRegistrationFee,
          ignoreManagmentFees: values.ignoreManagementFee,
        }),
      });

      return response.data.response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, CaisseVirementResponseBody & CaisseRequestBody>(
    createVirementTransaction,
    {
      onError: error => {
        toast.error(
          <SubmitResponseHandler
            message={error.message}
            errorCodeStr={error.errorCodeStr}
          />,
        );
      },
      ...options,
    },
  );
}

export function useCreateCashTransaction(
  options?:
    | Omit<UseMutationOptions<unknown, ErrorResponse, CaisseEspeceResponseBody & CaisseRequestBody>, "mutationFn">
    | undefined,
) {
  async function createCashTransaction(values: CaisseEspeceResponseBody & CaisseRequestBody) {
    try {
      const response = await axiosInstance.post(ENDPOINTS.CAISSE_CREATE_TRANSACTION_ENDPOINT, {
        type: values.type,
        tenantId: values.tenantId,
        numberOfMonths: values.numberOfMonths,
        transactionReference: values.transactionReference,
        content: JSON.stringify({
          price: values.price,
          handedOver: values.handedOver,
        }),
      });

      return response.data.response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, CaisseEspeceResponseBody & CaisseRequestBody>(createCashTransaction, {
    onError: error => {
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },
    ...options,
  });
}
