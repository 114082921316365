export const BASE_URL = (function (): string {
  switch (process.env.REACT_APP_TARGET_API) {
    case "local":
      return "http://localhost:7587/api/v1";
    case "dev":
      return "https://sakani-be-dev.sefrone.dev/api/v1";
    case "edge":
      return "https://sakani-be-edge.sefrone.dev/api/v1";
    case "main":
      return "https://api.sakani.dz/api/v1";
    default:
      return "https://sakani-be-dev.sefrone.dev/api/v1";
  }
})();

export const ENDPOINTS = {
  AUTH_LOGIN_ENDPOINT: BASE_URL + "/apiAuth/account/sakani/login",
  AUTH_LOGOUT_ENDPOINT: BASE_URL + "/apiAuth/account/sakani/logout/",

  TENANTS_GET_CONNECTED_LIST_ENDPOINT: BASE_URL + "/tenants/getConnectedTenants",
  TENANTS_GET_TENANT_DETAILED_INFOS_ENDPOINT: BASE_URL + "/tenants/getTenantDataById/", // {worksapceId}/{accountId}
  TENANTS_GET_TENANT_INVOICES_ENDPOINT: BASE_URL + "/payments/transactions/", // {worksapceId}/{accountId}

  DOWNLOAD_INVOICE: BASE_URL + "/payments/generatePdfFile/",

  CAISSE_CREATE_TRANSACTION_ENDPOINT: BASE_URL + "/payments/",
  CAISSE_QUOTE_TRANSACTION_ENDPOINT: BASE_URL + "/payments/quote/", // {tenantId}
};
