import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/ui/Spinner";
import { useAuth } from "../../contexts/Auth";
import { useGetTenant } from "../../api/tenants";
import { ReactComponent as BackArrow } from "../../assets/icons/backArrow.svg";
import TenantInvoices from "./TenantInvoices";
import TenantDetail from "./TenantDetail";
import { useState } from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Caisse from "./Caisse";
import { CaisseProvider } from "./Caisse/Provider";

export default function Tenant(): React.JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { selectedWorkspace } = useAuth();

  const { isLoading, data: tenant } = useGetTenant(id || "", selectedWorkspace?.workspaceId || "");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <article className="space-y-6">
      <section className="flex items-center gap-4">
        <BackArrow
          className="cursor-pointer rtl:rotate-180"
          onClick={() => navigate("/dashboard")}
        />
        <h1 className="text-2xl font-bold">{`${t("tenant")} : ${tenant?.fullName}`}</h1>
      </section>
      <section className="flex flex-col gap-y-8">
        {isLoading ? <Spinner /> : tenant && <TenantDetail tenantInfo={tenant} />}
        <div className="flex items-center justify-center gap-4">
          <PrimaryButton
            onClick={() => setSelectedTab(0)}
            className={`${selectedTab !== 0 && "!bg-white !text-primaryGreen"}`}
          >
            {t("historyPayment")}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => setSelectedTab(1)}
            className={`${selectedTab !== 1 && "!bg-white !text-primaryGreen"}`}
          >
            {t("newReceipt")}
          </PrimaryButton>
        </div>
        {selectedTab === 0 && <TenantInvoices />}
        {selectedTab === 1 && (
          <CaisseProvider
            maxAllowedNumberOfMonthsToPay={tenant?.maxAllowedNumberOfMonthsToPay || NaN}
            minAllowedNumberOfMonthsToPay={tenant?.minAllowedNumberOfMonthsToPay || NaN}
            tenantId={tenant?.id || ""}
            tenantName={tenant?.fullName || ""}
            lastPaidDate={tenant?.lastPaidDate || ""}
          >
            <Caisse />
          </CaisseProvider>
        )}
      </section>
    </article>
  );
}
